"use client";

import React, { memo, useCallback, useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import { useSegment } from "../../../hooks/use-segment";
import Providers from "@figmentjs/web-core/src/components/providers";

export const SegmentPageViewTracker: React.FC = () => {
  const { identify, trackPage } = useSegment();

  const { account: ethAccount } = Providers.useEthereumWallet();
  const [currentEthAccount, setCurrentEthAccount] = useState<
    string | undefined
  >();

  const [currentPath, setCurrentPath] = useState<string>("");
  const pathName = usePathname();

  const identifyEthAccount = useCallback(async () => {
    setCurrentEthAccount(ethAccount);
    await identify(ethAccount);
  }, [ethAccount, setCurrentEthAccount, identify]);

  const track = useCallback(() => {
    if (pathName) {
      setCurrentPath(pathName);
    }
    // Don't fire another event if we already logged this page
    if (currentPath !== pathName) {
      const parentUrl =
        window.location !== window.parent.location
          ? document.referrer
          : document.location.href;

      trackPage({ parentUrl });
    }
  }, [currentPath, pathName, trackPage, ethAccount]);

  useEffect(() => {
    if (ethAccount && ethAccount !== currentEthAccount) {
      // Fire identify call before trackPage and only once per account change
      identifyEthAccount();
      return;
    }

    track();
  }, [ethAccount, currentEthAccount, identifyEthAccount, track]);

  return null;
};

export default memo(SegmentPageViewTracker);
