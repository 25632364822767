import Rollbar, {
  getDefaultConfig,
} from "@figmentjs/rollbar-client/src/config";
import { baseURL } from "@app/utils/constants";

export const server = new Rollbar({
  ...getDefaultConfig(
    process.env.ROLLBAR_SERVER_ACCESS_TOKEN as string,
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA as string
  ),
  endpoint: `${baseURL}/api/rollbar`,
});

export const browser = new Rollbar({
  ...getDefaultConfig(
    process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_ACCESS_TOKEN as string,
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA as string
  ),
  endpoint: `${baseURL}/api/rollbar`,
});
